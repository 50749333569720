import React, { useEffect } from "react";
import styled from "styled-components";
import {
  mainWhite,
  raleway,
  lessBlack,
  mainRed,
  transHover,
  screen,
  fadeIn,
} from "../common/variables";
import ScrollLock from "react-scrolllock";

const Wrapper = styled.div`
  animation: ${fadeIn} 0.3s ease-out;
  background: rgba(0, 0, 0, 0.81);
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 20;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: scroll;

  .modal {
    border-radius: 20px;
    position: absolute;
    background: ${mainWhite};
    padding: 25px;
    max-width: 340px;
    width: 100%;
    display: flex;
    flex-direction: column;
    top: 45px;
    @media ${screen.small} {
      padding: 40px;
      max-width: 730px;
      flex-direction: row;
      top: 145px;
    }
    @media ${screen.medium} {
      padding: 55px;
      top: 50%;
      transform: translateY(-50%);
    }
    @media ${screen.large} {
      padding: 70px;
      margin: 0;
      max-width: 1200px;
    }

    &__close-icon {
      color: ${mainRed};
      font-size: 1.6rem;
      font-weight: 900;
      font-family: ${raleway};
      position: absolute;
      top: 16px;
      right: 18px;
      cursor: pointer;
      transition: ${transHover};
      @media ${screen.small} {
        font-size: 1.8rem;
        top: 20px;
        right: 22px;
      }
      @media ${screen.withCursor} {
        &:hover {
          color: ${lessBlack};
        }
      }
    }

    &__img {
      width: 100%;
      max-width: 200px;
      @media ${screen.small} {
        max-width: 340px;
      }
    }

    &__text {
      font-family: ${raleway};
      max-width: 900px;
      width: 100%;
      @media ${screen.small} {
        padding-left: 40px;
      }
      @media ${screen.medium} {
        padding-left: 70px;
      }

      .fullname {
        color: ${lessBlack};
        font-size: 1.68rem;
        font-weight: 600;
        line-height: 1.3;
        margin-top: 8px;
        @media ${screen.small} {
          font-size: 2.5rem;
          margin-top: 10px;
        }
      }

      .company {
        color: ${lessBlack};
        font-size: 1rem;
        font-weight: 400;
        @media ${screen.small} {
          font-size: 1.1rem;
        }
      }

      .description {
        color: ${lessBlack};
        margin-top: 20px;

        p {
          font-size: 1.1rem;
          font-weight: 300;
          margin-bottom: 12px;
          @media ${screen.small} {
            font-size: 1.15rem;
            margin-bottom: 16px;
          }
        }

        a {
          color: ${lessBlack};
          font-weight: 700;
          text-decoration: underline;
          @media ${screen.withCursor} {
            &:hover {
              color: ${mainRed};
            }
          }
        }
      }
    }
  }
`;

const SpeakerModal = ({ selectedSpeaker, closeSpeakerModal }) => {
  const { name, company, position, image, description } = selectedSpeaker;

  useEffect(() => {
    const userClickedOutside = e => {
      if (typeof e.target.className === "string") {
        if (e.target.className.includes("Wrapper")) {
          closeSpeakerModal();
        }
      }
    };
    window.addEventListener("click", userClickedOutside);

    return () => {
      window.removeEventListener("click", userClickedOutside);
    };
  }, []);

  return (
    <ScrollLock>
      <Wrapper>
        <div className="modal">
          <div className="modal__img">
            <img style={{ width: "100%" }} src={image} alt={name} />
          </div>
          <div className="modal__text">
            <h3 className="fullname">{name}</h3>
            <p className="company">
              {company} - {position}
            </p>
            <div
              className="description"
              dangerouslySetInnerHTML={{ __html: description }}
            />
          </div>
          <span className="modal__close-icon" onClick={closeSpeakerModal}>
            X
          </span>
        </div>
      </Wrapper>
    </ScrollLock>
  );
};

export default SpeakerModal;
