import React, { useEffect } from "react";
import styled from "styled-components";
import {
  mainWhite,
  raleway,
  lessBlack,
  mainBlack,
  mainRed,
  transHover,
  screen,
  fadeIn,
} from "../common/variables";
import Img from "gatsby-image";
import ScrollLock from "react-scrolllock";

const Wrapper = styled.div`
  animation: ${fadeIn} 0.3s ease-out;
  background: rgba(0, 0, 0, 0.81);
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 20;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: scroll;

  .modal {
    border-radius: 20px;
    position: absolute;
    background: ${mainWhite};
    padding: 40px 25px 25px 25px;
    max-width: 340px;
    width: 100%;
    font-family: ${raleway};
    top: 45px;
    @media ${screen.small} {
      padding: 40px;
      max-width: 730px;
      top: 145px;
    }
    @media ${screen.medium} {
      padding: 55px;
      top: 50%;
      transform: translateY(-50%);
    }
    @media ${screen.large} {
      padding: 70px;
      margin: 0;
      max-width: 800px;
    }

    &__heading {
      color: ${mainRed};
      display: block;
      font-weight: 900;
      font-size: 1.4rem;
      margin-bottom: 45px;
      text-transform: uppercase;
      @media ${screen.small} {
        font-size: 1.5rem;
      }
    }

    &__person {
      display: flex;

      .person-img {
        max-width: 110px;
        width: 100%;
        @media ${screen.small} {
          max-width: 140px;
        }
        img {
          display: block;
          width: 100%;
        }
      }

      .person-details {
        margin-left: 14px;
        align-self: flex-end;
        @media ${screen.small} {
          margin-left: 20px;
        }

        &__fullname {
          color: ${lessBlack};
          font-size: 1.12rem;
          font-weight: 700;
          margin-bottom: 4px;
          text-transform: uppercase;
        }

        &__position {
          color: ${lessBlack};
          display: block;
          font-size: 0.87rem;
          font-weight: 700;
          margin-bottom: 8px;
          text-transform: uppercase;
        }

        &__time {
          color: ${mainRed};
          display: block;
          font-weight: 700;
          font-size: 0.9rem;

          @media ${screen.small} {
            font-size: 1rem;
          }
        }
      }
    }

    .description {
      margin-top: 18px;
      @media ${screen.small} {
        margin-top: 28px;
      }
      p {
        color: ${mainBlack};
        font-size: 1.12rem;
        margin-bottom: 20px;
        font-weight: 300;
        &:last-child {
          margin-bottom: 0;
        }
      }
    }

    &__close-icon {
      color: ${mainRed};
      font-size: 1.6rem;
      font-weight: 900;
      position: absolute;
      top: 16px;
      right: 18px;
      cursor: pointer;
      transition: ${transHover};
      @media ${screen.small} {
        font-size: 1.8rem;
        top: 20px;
        right: 22px;
      }
      @media ${screen.withCursor} {
        &:hover {
          color: ${lessBlack};
        }
      }
    }
  }
`;

const SessionModal = ({ selectedSession, closeSessionModal }) => {
  const {
    title,
    speakerName,
    speakerPosition,
    roomAndTime,
    description,
    image,
  } = selectedSession;

  useEffect(() => {
    const userClickedOutside = e => {
      if (typeof e.target.className === "string") {
        if (e.target.className.includes("Wrapper")) {
          closeSessionModal();
        }
      }
    };
    window.addEventListener("click", userClickedOutside);

    return () => {
      window.removeEventListener("click", userClickedOutside);
    };
  }, []);

  return (
    <ScrollLock>
      <Wrapper>
        <div className="modal">
          <h4 className="modal__heading">{title}</h4>
          <div className="modal__person">
            <div className="person-img">
              {image && <Img fluid={image} alt="Speaker" />}
            </div>
            <div className="person-details">
              <h6 className="person-details__fullname">{speakerName}</h6>
              <span className="person-details__position">
                {speakerPosition}
              </span>
              <span className="person-details__time">{roomAndTime}</span>
            </div>
          </div>
          <div
            className="description"
            dangerouslySetInnerHTML={{ __html: description }}
          />
          {/* <span className="modal__heading">{type} SPONSOR</span>
          <div className="modal__logo">
            {image && <Img fluid={image} alt={alt} />}
          </div>
          <h3 className="modal__name">{name}</h3>
     

          <div
            className="modal__description"
            dangerouslySetInnerHTML={{ __html: description }}
          /> */}
          <span className="modal__close-icon" onClick={closeSessionModal}>
            X
          </span>
        </div>
      </Wrapper>
    </ScrollLock>
  );
};

export default SessionModal;
