import React, { useEffect } from "react";
import styled from "styled-components";
import {
  mainWhite,
  raleway,
  lessBlack,
  mainBlack,
  mainRed,
  transHover,
  screen,
  fadeIn,
} from "../common/variables";
import Img from "gatsby-image";
import ScrollLock from "react-scrolllock";

const Wrapper = styled.div`
  animation: ${fadeIn} 0.3s ease-out;
  background: rgba(0, 0, 0, 0.81);
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 20;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: scroll;

  .modal {
    border-radius: 20px;
    position: absolute;
    background: ${mainWhite};
    padding: 40px 25px 25px 25px;
    max-width: 340px;
    width: 100%;
    font-family: ${raleway};
    top: 45px;
    @media ${screen.small} {
      padding: 40px;
      max-width: 730px;
      top: 145px;
    }
    @media ${screen.medium} {
      padding: 55px;
      top: 50%;
      transform: translateY(-50%);
    }
    @media ${screen.large} {
      padding: 70px;
      margin: 0;
      max-width: 800px;
    }

    &__heading {
      color: ${mainRed};
      display: block;
      font-weight: 900;
      font-size: 1.4rem;
      margin-bottom: 45px;
      text-transform: uppercase;
      @media ${screen.small} {
        font-size: 1.5rem;
      }
    }

    &__logo {
      max-width: 200px;
      width: 100%;
      @media ${screen.small} {
        max-width: 320px;
      }
    }

    &__name {
      font-weight: 700;
      font-size: 1.3rem;
      margin-top: 30px;
      @media ${screen.small} {
        font-size: 1.5rem;
      }
    }

    &__website-link {
      color: ${mainRed};
      display: table;
      font-size: 1.1rem;
      font-weight: 700;
      margin-top: 5px;
      @media ${screen.small} {
        font-size: 1.25rem;
      }
      &:hover {
        text-decoration: underline;
      }
    }

    &__description {
      margin-top: 18px;
      p {
        color: ${mainBlack};
        font-size: 1.12rem;
        margin-bottom: 20px;
        font-weight: 300;
        &:last-child {
          margin-bottom: 0;
        }
      }

      a {
        color: ${mainBlack};
        text-decoration: underline;
      }
    }

    &__close-icon {
      color: ${mainRed};
      font-size: 1.6rem;
      font-weight: 900;
      position: absolute;
      top: 16px;
      right: 18px;
      cursor: pointer;
      transition: ${transHover};
      @media ${screen.small} {
        font-size: 1.8rem;
        top: 20px;
        right: 22px;
      }
      @media ${screen.withCursor} {
        &:hover {
          color: ${lessBlack};
        }
      }
    }
  }
`;

const SpeakerModal = ({ selectedSponsor, closeSponsorModal }) => {
  const { name, website, description, image, type } = selectedSponsor;

  useEffect(() => {
    const userClickedOutside = e => {
      if (typeof e.target.className === "string") {
        if (e.target.className.includes("Wrapper")) {
          closeSponsorModal();
        }
      }
    };
    window.addEventListener("click", userClickedOutside);

    return () => {
      window.removeEventListener("click", userClickedOutside);
    };
  }, []);

  const trimURL = str => {
    const removedHttps = str.replace(/(^\w+:|^)\/\//, "");
    return removedHttps.endsWith("/")
      ? removedHttps.slice(0, -1)
      : removedHttps;
  };

  return (
    <ScrollLock>
      <Wrapper>
        <div className="modal">
          <span className="modal__heading">{type} SPONSOR</span>
          <div className="modal__logo">
            {image && <img style={{ width: "100%" }} src={image} alt={name} />}
          </div>
          <h3 className="modal__name">{name}</h3>
          {website && (
            <a
              className="modal__website-link"
              href={website}
              target="_blank"
              rel="noopener noreferrer"
            >
              {trimURL(website)}
            </a>
          )}

          <div
            className="modal__description"
            dangerouslySetInnerHTML={{ __html: description }}
          />

          <span className="modal__close-icon" onClick={closeSponsorModal}>
            X
          </span>
        </div>
      </Wrapper>
    </ScrollLock>
  );
};

export default SpeakerModal;
