import React, { useState } from "react";
import Layout from "../components/layout";
import SEO from "../components/seo";
import styled from "styled-components";
import {
  raleway,
  mainWhite,
  dirtyWhite,
  mainBlack,
  mainRed,
  lightRed,
  lessBlack,
  innerWidth,
  transHover,
  screen,
} from "../components/common/variables";
import colouredLogo from "../images/coloured-logo.svg";
import logo from "../images/coloured-logo.png";
import MailchimpForm from "../components/mailchimp-form";
import SpeakerModal from "../components/speaker-modal";
import SponsorModal from "../components/sponsor-modal";
import SessionModal from "../components/session-modal";
import Obfuscate from "react-obfuscate";
import Gallery from "../components/gallery";

const Wrapper = styled.div`
  p {
    color: ${mainBlack};
  }

  .banner {
    background-image: url("/images/background_old.avif");
    background-position: calc(400px + 50vw) 0;
    background-size: cover;
    color: ${mainWhite};
    font-family: ${raleway};
    text-align: center;
    padding: 220px 0 172px 0;
    position: relative;
    @media ${screen.small} {
      background-size: cover;
      background-position: -607px 0;
      padding: 320px 0 255px 0;      
    }
    @media ${screen.medium} {
      padding: 323px 0 242px 0;
      background-position: center;
    }
    @media ${screen.large} {
      padding: 320px 0 255px 0;
    }

    &__heading {
      font-size: 2.1rem;
      font-weight: 600;
      line-height: 1.3;
      text-shadow: 1px 2px 1px #000;
      position: relative;
      z-index: 2;
      @media ${screen.small} {
        font-size: 4.42rem;
        text-shadow: 2px 3px 2px #000;
      }
      @media ${screen.medium} {
        font-size: 5.62rem;
      }
    }

    &__subheading {
      font-size: 1.4rem;
      font-weight: 500;
      text-shadow: 1px 1px 2px #000;
      position: relative;
      z-index: 2;
      @media ${screen.small} {
        font-size: 2.6rem;
        text-shadow: 2px 2px 4px #000;
      }
      @media ${screen.medium} {
        font-size: 3.1rem;
      }
    }

    &::after {
      content: '';
      background: rgba(12, 29, 48, 0.6);
      position: absolute;
      z-index: 1;
      width: 100%;
      height: 100%;
      top: 0;
      left: 0;
    }
  }

  .intro {
    display: flex;
    max-width: ${innerWidth};
    margin: 0 auto;
    padding: 55px 25px 55px 25px;
    flex-direction: column;
    @media ${screen.small} {
      padding: 55px 35px;
      flex-direction: row;
    }
    @media ${screen.large} {
      padding: 70px 0;
    }

    &__text {
      color: ${lessBlack};
      font-family: ${raleway};
      order: 1;
      @media ${screen.small} {
        padding-right: 50px;
        order: 0;
      }
      @media ${screen.medium} {
        padding-right: 100px;
      }

      .intro-heading {
        font-size: 1.35rem;
        font-weight: 900;
        @media ${screen.small} {
          font-size: 2.4rem;
        }
        @media ${screen.medium} {
          font-size: 2.4rem;
        }
      }

      .intro-description {
        margin-top: 12px;
        /* margin-bottom: 55px; */

        @media ${screen.small} {
          margin-top: 16px;
          /* margin-bottom: 70px; */
        }

        h2 {
          font-size: 1.3rem;
          font-weight: 500;
          margin-bottom: 5px;
          @media ${screen.small} {
            font-size: 1.49rem;
          }
        }

        p {
          font-size: 1.1rem;
          font-weight: 300;
          @media ${screen.small} {
            font-size: 1.15rem;
          }
        }
      }

      /* .contact-heading {
        font-size: 1.2rem;
        font-weight: 900;
        margin-bottom: 3px;
        @media ${screen.small} {
          font-size: 1.52rem;
          margin-bottom: 5px;
        }
      }

      .contact-description {
        font-size: 1.1rem;
        font-weight: 300;
        @media ${screen.small} {
          font-size: 1.15rem;
        }
      }

      .contact-link {
        background: ${mainRed};
        border-radius: 8px;
        color: ${mainWhite};
        display: table;
        font-weight: 600;
        font-size: 0.95rem;
        padding: 11px 42px;
        margin-top: 15px;
        transition: ${transHover};
        @media ${screen.small} {
          font-size: 1rem;
          padding: 13px 57px;
          margin-top: 32px;
        }
        @media ${screen.withCursor} {
          &:hover {
            background: ${lightRed};
          }
        }
      } */
    }

    &__brand {
      max-width: 140px;
      width: 100%;
      margin-bottom: 20px;
      @media ${screen.small} {
        margin-bottom: 0;
        max-width: 175px;
      }

   
    }
  }

  .organiser-wrapper {
    font-family: ${raleway};
    max-width: ${innerWidth};
    margin: 0 auto 55px auto;
    padding: 0 25px 40px 25px;
    flex-direction: column;
    @media ${screen.small} {
      padding: 0 35px;
      margin: 0 auto 70px auto;
    }
    @media ${screen.large} {
      padding: 0;
    }

    .organiser {
      background: ${dirtyWhite};
      border-radius: 11px;
      box-shadow: 0 4px 12px -2px rgba(0, 0, 0, 0.2);
      position: relative;
      display: contents;
      
      img {
        width: 100%;
      }

      &__heading {
        color: ${mainWhite};
        border-radius: 11px 11px 0 0;
        background: ${mainRed};
        font-size: 1.2rem;
        font-weight: 900;
        font-family: ${raleway};
        padding: 10px 25px;
        @media ${screen.small} {
          font-size: 1.52rem;
        }

        span {
          font-weight: 300;
        }
      }

      &__list {
        display: flex;
        flex-wrap: wrap;
        padding: 10px 35px 20px 35px;
        @media ${screen.small} {
          padding: 20px 60px 30px 60px;
        }
        @media ${screen.medium} {
          padding: 25px 80px 35px 80px;
        }
        @media ${screen.large} {
          padding: 30px 100px 40px 100px;
        }

        .organiser-item {
          margin: 14px 15px;
          width: calc(50% - 30px);
          @media ${screen.small} {
            margin: 20px;
            width: calc(33.33% - 40px);
          }
          @media ${screen.medium} {
            width: calc(25% - 40px);
          }

          &__img {
            border-radius: 11px;
            overflow: hidden;
          }

          &__name {
            color: ${mainBlack};
            font-weight: 700;
            font-size: 1.15rem;
            margin-top: 5px;
            padding-right: 10px;
            line-height: 1.35;
            @media ${screen.small} {
              margin-top: 8px;
              font-size: 1.2rem;
              padding-right: 20px;
            }
          }
        }
      }
    }
  }

  .agenda-wrapper {
    /* margin-bottom: 55px;
    @media ${screen.small} {
      margin-bottom: 55px;
    }
    @media ${screen.medium} {
      margin-bottom: 70px;
    } */

    .agenda {
      max-width: ${innerWidth};
      margin: 0 auto;
      padding: 55px 25px 0 25px;
      flex-direction: column;
      @media ${screen.small} {
        padding: 55px 0 0 35px;
        flex-direction: row;
      }
      @media ${screen.large} {
        padding: 70px 0 0 0;
      }

      &__heading {
        color: ${lessBlack};
        font-size: 1.2rem;
        font-weight: 900;
        font-family: ${raleway};
        margin-bottom: 5px;
        @media ${screen.small} {
          font-size: 1.52rem;
        }
      }

      &__description {
        color: ${mainBlack};
        font-family: ${raleway};
        font-size: 1.1rem;
        font-weight: 300;
        margin-bottom: 16px;
        @media ${screen.small} {
          font-size: 1.15rem;
        }
        @media ${screen.medium} {
          white-space: pre-line;
        }
      }

      &__schedule-list {
        margin-top: 26px;
        @media ${screen.medium} {
          margin-top: 36px;
        }

        .schedule-item {
          display: flex;
          font-family: ${raleway};
          font-size: 1.1rem;
          margin-bottom: 15px;
          @media ${screen.small} {
            font-size: 1.15rem;
          }

          &__time {
            color: ${lessBlack};
            display: block;
            font-weight: 600;
            max-width: 175px;
            width: 100%;
          }

          &__label {
            color: ${mainBlack};
            display: block;
            font-weight: 300;
          }
        }
      }

      &__bottom-txt {
        color: ${mainRed};
        font-weight: 600;
        font-family: ${raleway};
        margin-top: 26px;
        @media ${screen.medium} {
          margin-top: 36px;
        }
      }
    }
  }

  .sessions-wrapper {
    /* margin-bottom: 55px;
    @media ${screen.small} {
      margin-bottom: 55px;
    }
    @media ${screen.medium} {
      margin-bottom: 70px;
    } */

    .sessions {
      max-width: ${innerWidth};
      margin: 0 auto;
      padding: 55px 25px 55px 25px;
      flex-direction: column;
      @media ${screen.small} {
        padding: 55px 35px;
        flex-direction: row;
      }
      @media ${screen.large} {
        padding: 70px 0 0 0;
      }

      &__heading {
        color: ${lessBlack};
        font-size: 1.2rem;
        font-weight: 900;
        font-family: ${raleway};
        margin-bottom: 5px;
        @media ${screen.small} {
          font-size: 1.52rem;
        }
      }

      &__description {
        p {
          color: ${mainBlack};
          font-family: ${raleway};
          font-size: 1.1rem;
          font-weight: 300;
          margin-bottom: 16px;
          @media ${screen.small} {
            font-size: 1.15rem;
          }
          @media ${screen.medium} {
            white-space: pre-line;
          }
          &:last-child {
            margin-bottom: 0;
          }
        }
      }

      .sessions-heading {
        display: flex;
        margin-top: 10px;
        @media ${screen.small} {
        }
       

        &__label {
          color: ${mainWhite};
          background: ${mainRed};
          font-size: 0.6rem;
          font-weight: 900;
          font-family: ${raleway};
          padding: 10px 0;
          text-align: center;
          width: 21%;
          @media ${screen.small} {
            font-size: 1.1rem;
          }
          @media ${screen.medium} {
            font-size: 1.2rem;
          }
          
          span {

          }

          &--time {
            width: 16%;
          }

          

          &:first-child {
            border-radius: 11px 0 0 0;
          }

          &:last-child {
            border-radius: 0 11px 0 0;
          }
        }
      }

      .sessions-table {
        width: 100%;
        border-bottom: 1px ${lessBlack} solid;
        border-radius: 11px 11px 0 0;
        
        &__row {
          display: flex;

          .col {
            width: 21%;
            border-right: 1px ${lessBlack} solid;

            &__label {
              display: block;
              font-family: ${raleway};
              font-weight: 700;
              font-size: 0.5rem;
              padding: 12px 5px;
              text-align: center;
              transition: ${transHover};
              @media ${screen.small} {
                font-size: 0.77rem;
                padding: 12px 10px;
              }
              @media ${screen.medium} {
                font-size: 0.87rem;
              }

            &--time {
              color: ${mainRed};
            }
            
            &--session {
              &:hover {
                cursor: pointer;
                color: ${mainRed};
              }
             }
            }
           
            &--time {
              width: 16%;
              border-left: 1px ${lessBlack} solid;
            }

             
          }

          &:nth-child(odd) {
            background: ${dirtyWhite};
          }

          &--row3, &--row4, &--row5, &--row6, &--row7, &--row8 {

            .col {
              &__label {
              
                &--expo {
                  background: ${dirtyWhite};
                  color: ${dirtyWhite};
                  cursor: default;
                  ::selection { color: ${dirtyWhite}; }
                }
              }
            }
          }

          &--row3 {

            .col {
              &__label {
              
                &--expo {
                  background: ${dirtyWhite};
                  color: ${lessBlack};
                  cursor: default;
                  @media ${screen.large} {
                    background: ${dirtyWhite};
                    color: ${dirtyWhite};
                  }
                }
              }
            }
          }

          &--row6 {
            .col {
              &__label {
              
                &--expo {
                  min-height: 100px;
                  @media ${screen.small} {
                    min-height: 90px;
                  }
                }
              }
            }
          }

          &--row4 {
            .col {
              &__label {
              
                &--expo {
                  min-height: 90px;
                  @media ${screen.small} {
                    min-height: auto;
                  }
                }
              }
            }
          }

          &--row3 {
            position: relative;

            &::after {
              content: 'Partner Expo, Photo Booth, AppTrail Others';
              position: absolute;
              display: block;
              font-family: ${raleway};
              font-weight: 700;
              font-size: 0.5rem;
              top: 157px;
              left: 70px;
              max-width: 55px;
              text-align: center;
              display: none;
              @media ${screen.small} {
                font-size: 0.77rem;
                left: calc(16vw);
                top: 170px;
                max-width: 128px;
              }
              @media ${screen.medium} {
                font-size: 0.87rem;
                left: 200px;
                max-width: 178px;
                top: 157px;
              }
              @media ${screen.large} {
                left: 200px;
                display: block;
              }
            }
          }

          &--row8 {
            position: relative; 
             .col {
               border-right: 0;
               &:last-child {
                 border-right: 1px ${lessBlack} solid;
               }
               &__label {
                 visibility: hidden;

                 &--time {
                   visibility: visible;
                 }
               }
               
             }
             &::after {
                 content: 'TEA BREAK';
                 position: absolute;
                 display: block;
                 font-family: ${raleway};
                 font-weight: 700;
                 font-size: 0.5rem;
                 transition: ${transHover};
                 top: 50%;
                 left: calc(50% + 22px);
                 transform: translate(-50%, -50%);
                 @media ${screen.small} {
                   font-size: 0.77rem;
                   left: calc(50% + 86px);
                 }
                 @media ${screen.medium} {
                   font-size: 0.87rem;
                 }
              }
          }

          &--row12 {
            position: relative; 
             .col {
               border-right: 0;
               &:last-child {
                 border-right: 1px ${lessBlack} solid;
               }
               &__label {
                 visibility: hidden;

                 &--time {
                   visibility: visible;
                 }
               }
               
             }
             &::after {
                 content: 'NETWORKING & DRINKS';
                 position: absolute;
                 display: block;
                 font-family: ${raleway};
                 font-weight: 700;
                 font-size: 0.5rem;
                 transition: ${transHover};
                 top: 50%;
                 left: calc(50% + 22px);
                 transform: translate(-50%, -50%);
                 @media ${screen.small} {
                   font-size: 0.77rem;
                   left: calc(50% + 86px);
                 }
                 @media ${screen.medium} {
                   font-size: 0.87rem;
                 }
              }
          } 
        }
      }
    }
  }

  .interest-wrapper {
    background: ${mainRed};

    .interest {
      display: flex;
      max-width: ${innerWidth};
      margin: 0 auto;
      padding: 0 25px 0 25px;
      flex-direction: column;
      @media ${screen.small} {
        padding: 0 0 0 35px;
        flex-direction: row;
      }
      @media ${screen.large} {
        padding: 0;
      }

      &__text {
        font-family: ${raleway};
        padding: 20px 0 55px 0;
        width: 100%;
        order: 1;
        @media ${screen.small} {
          width: 60%;
          padding: 55px 0;
          order: 0;
        }
        @media ${screen.medium} {
          padding: 70px 0;
        }

        .interest-heading {
          font-size: 1.2rem;
          font-weight: 900;
          margin-bottom: 3px;
          @media ${screen.small} {
            font-size: 1.52rem;
            margin-bottom: 5px;
          }
        }

        .interest-description {
          p {
            font-size: 1.1rem;
            font-weight: 300;
            margin-bottom: 16px;
            @media ${screen.small} {
              font-size: 1.15rem;
            }
          }
        }

        .contact-link {
          background: ${mainWhite};
          border-radius: 8px;
          color: ${mainBlack};
          display: table;
          font-weight: 600;
          font-size: 0.95rem;
          padding: 11px 42px;
          margin-top: 15px;
          transition: ${transHover};
          @media ${screen.small} {
            font-size: 1rem;
            padding: 13px 57px;
            margin-top: 32px;
          }
          @media ${screen.withCursor} {
            &:hover {
              background: ${mainBlack};
              color: ${mainWhite};
            }
          }
        }
      }

      &__featured-img {
        background-image: url("https://images.prismic.io/singapore-dreaming%2Fadebaf3c-eabb-42a5-925e-d727f58c3b45_demo1.png?auto=compress,format");
        background-repeat: no-repeat;
        background-position: center;
        background-size: cover;
        width: 100%;
        margin-top: 55px;
        min-height: 230px;
        @media ${screen.small} {
          width: 40%;
          margin-left: 50px;
          margin-top: 0;
        }
        @media ${screen.medium} {
          margin-left: 100px;
        }
      }
    }
  }

  .event-wrapper {
    .event {
      box-shadow: 0 4px 12px -2px rgba(0, 0, 0, 0.35);
      border-radius: 22px;
      background: ${dirtyWhite};
      display: flex;
      max-width: ${innerWidth};
      margin: 55px 25px;
      overflow: hidden;
      flex-direction: column;
      @media ${screen.small} {
        margin: 55px 35px;
        flex-direction: row;
      }
      @media ${screen.large} {
        margin: 70px auto;
      }

      &__text {
        padding: 30px 35px 25px 35px;
        font-family: ${raleway};
        width: 100%;
        @media ${screen.small} {
          width: 60%;
          padding: 55px 0 55px 55px;
        }
        @media ${screen.medium} {
          padding: 70px 0 70px 70px;
        }

        .event-heading {
          color: ${mainBlack};
          font-size: 1.6rem;
          font-weight: 500;
          @media ${screen.small} {
            font-size: 2.45rem;
          }
        }

        .event-description {
          margin-top: 8px;
          @media ${screen.small} {
            margin-top: 16px;
          }

          p {
            font-size: 1.1rem;
            font-weight: 300;
            margin-bottom: 16px;
            @media ${screen.small} {
              font-size: 1.15rem;
            }
          }
        }

        .event-location {
          display: table;
          color: ${mainBlack};
          font-weight: 600;
          font-size: 1.1rem;
          line-height: 1.3;
          transition: ${transHover};
          @media ${screen.small} {
            white-space: pre;
            font-size: 1.15rem;
          }
          @media ${screen.withCursor} {
            &:hover {
              color: ${mainRed};
            }
          }
        }
      }

      &__featured-img {
        background-image: ${props => `url(${props.eventURL})`};
        background-repeat: no-repeat;
        background-position: center;
        background-size: cover;
        min-height: 230px;
        width: 100%;
        @media ${screen.small} {
          margin-left: 50px;
          width: 40%;
        }
        @media ${screen.medium} {
          margin-left: 100px;
        }
      }
    }
  }

  .speaker-wrapper {
    font-family: ${raleway};
    color: ${lessBlack};

    .speaker {
      max-width: calc(${innerWidth});
      width: 100%;
      margin: 0 auto;
      padding: 0 25px 39px 25px;
      @media ${screen.small} {
        padding: 0 35px 35px 35px;
      }
      @media ${screen.large} {
        padding: 0 0 50px 0;
      }

      &__top {
        .top-speaker-heading {
          font-size: 1.2rem;
          font-weight: 900;
          margin-bottom: 3px;
          @media ${screen.small} {
            font-size: 1.52rem;
            margin-bottom: 5px;
          }
        }

        .top-speaker-subheading {
          color: ${lessBlack};
          font-size: 1.1rem;
          font-weight: 300;
          @media ${screen.small} {
            font-size: 1.15rem;
          }
        }

        .top-list {
          display: flex;
          flex-wrap: wrap;
          margin: 0 -11px;
          @media ${screen.small} {
            margin: 0;
          }

          &__item {
            margin-left: 11px;
            margin-right: 11px;
            margin-top: 11px;
            margin-bottom: 11px;
            width: calc(50% - 22px);
            @media ${screen.small} {
              margin-left: 0;
              margin-right: 40px;
              margin-top: 20px;
              margin-bottom: 20px;
              width: 26%;
            }

            .speaker-img {
              cursor: pointer;

              img {
                border-radius: 12px;
                width: 100%;
              }
            }

            .speaker-name {
              cursor: pointer;
              font-weight: 700;
              font-size: 1.12rem;
              margin-top: 3px;
              padding-right: 10px;
              @media ${screen.small} {
                margin-top: 5px;
                font-size: 1.2rem;
                padding-right: 20px;
              }
            }

            .speaker-company {
              font-size: 0.82rem;
              padding-right: 10px;
              @media ${screen.small} {
                font-size: 0.92rem;
                padding-right: 20px;
              }
            }
          }
        }
      }

      &__bottom {
        margin-top: 35px;

        .bottom-speaker-heading {
          font-size: 1.2rem;
          font-weight: 900;
          margin-bottom: 3px;
          @media ${screen.small} {
            font-size: 1.52rem;
            margin-bottom: 5px;
          }
        }

        .bottom-speaker-subheading {
          color: ${lessBlack};
          font-size: 1.1rem;
          font-weight: 300;
          @media ${screen.small} {
            font-size: 1.15rem;
          }
        }

        .bottom-list {
          display: flex;
          flex-wrap: wrap;
          margin: 0 -11px;
          @media ${screen.small} {
            margin: 0 -20px;
          }

          &__item {
            margin: 11px;
            width: calc(50% - 22px);
            @media ${screen.small} {
              margin: 20px;
              width: calc(25% - 40px);
            }
            .speaker-img {
              cursor: pointer;

              img {
                border-radius: 12px;
                width: 100%;
              }
            }

            .speaker-name {
              cursor: pointer;
              font-weight: 700;
              font-size: 1.12rem;
              margin-top: 3px;
              padding-right: 10px;
              @media ${screen.small} {
                margin-top: 5px;
                font-size: 1.2rem;
                padding-right: 20px;
              }
            }

            .speaker-company {
              font-size: 0.82rem;
              padding-right: 10px;
              @media ${screen.small} {
                font-size: 0.92rem;
                padding-right: 20px;
              }
            }
          }
        }
      }
    }
  }

  .contact-speaker {
    max-width: calc(${innerWidth});
    font-family: ${raleway};
    width: 100%;
    margin: 0 auto;
    padding: 0 25px 55px 25px;
    @media ${screen.small} {
      padding: 0 35px 35px 35px;
    }
    @media ${screen.large} {
      padding: 0 0 70px 0;
    }

    &__heading {
      font-size: 1.2rem;
      font-weight: 900;
      margin-bottom: 3px;
      @media ${screen.small} {
        font-size: 1.52rem;
        margin-bottom: 5px;
      }
    }

    &__description {
      font-size: 1.1rem;
      font-weight: 300;
      @media ${screen.small} {
        font-size: 1.15rem;
      }
    }

    &__cta-link {
      background: ${mainRed};
      border-radius: 8px;
      color: ${mainWhite};
      display: table;
      font-weight: 600;
      font-size: 0.95rem;
      padding: 11px 42px;
      margin-top: 15px;
      transition: ${transHover};
      @media ${screen.small} {
        font-size: 1rem;
        padding: 13px 57px;
        margin-top: 32px;
      }
      @media ${screen.withCursor} {
        &:hover {
          background: ${lightRed};
        }
      }
    }
  }

  .sponsor-wrapper {
    
    .sponsor {
      max-width: ${innerWidth};
      margin: 0 auto;
      font-family: ${raleway};
      color: ${lessBlack};
      padding: 55px 25px;
      @media ${screen.small} {
        padding: 55px 35px;
      }
      @media ${screen.large} {
        padding: 70px 0;
      }

      .sponsor-heading {
        font-size: 1.2rem;
        font-weight: 900;
        margin-bottom: 16px;
        @media ${screen.small} {
          font-size: 1.52rem;
          margin-bottom: 20px;
        }
      }

      .sponsor-subheading {
        background: ${dirtyWhite};
        font-size: 1.05rem;
        font-weight: 500;
        padding: 9px 16px;
        margin-bottom: 16px;
        text-transform: uppercase;
        @media ${screen.small} {
          font-size: 1.32rem;
          padding: 10px 18px;
          margin-bottom: 20px;
        }
      }

      &__3-col {
        margin-bottom: 15px;

        .list {
          display: flex;
          flex-wrap: wrap;
          margin: 0 -20px;
          @media ${screen.small} {
            margin: 0 -28px;
          }

          &__item {
            width: calc(50% - 40px);
            margin: 0 20px 40px 20px;
            @media ${screen.small} {
              width: calc(33.33% - 70px);
              margin: 0 35px 56px 35px;
            }

            img {
              width: 100%;
            }
          }
        }
      }

      &__4-col {
        margin-bottom: 15px;

        .list {
          display: flex;
          flex-wrap: wrap;
          margin: 0 -14px;
          @media ${screen.small} {
            margin: 0 -20px;
          }

          &__item {
            width: calc(33.33% - 28px);
            margin: 0 14px 28px 14px;
            @media ${screen.small} {
              width: calc(25% - 60px);
              margin: 0 30px 40px 30px;
            }
            img {
              width: 100%;
            }
          }
        }
      }

      &__5-col {
        margin-bottom: 15px;

        .list {
          display: flex;
          flex-wrap: wrap;
          margin: 0 -10px;
          @media ${screen.small} {
            margin: 0 -18px;
          }

          &__item {
            width: calc(25% - 20px);
            margin: 0 10px 20px 10px;
            @media ${screen.small} {
              width: calc(20% - 54px);
              margin: 0 27px 36px 27px;
            }

            img {
              width: 100%;
            }

            .label {
              display: block;
              text-align: center;
              color: ${mainBlack};
              margin-top: 14px;
              font-size: 0.7rem;
              @media ${screen.small} {
                font-size: 0.95rem;
              }
            }
          }
        }
      }
    }
  }
`;

const IndexPage = () => {
  const [isSpeakerModalOpen, setSpeakerModalOpen] = useState(false);
  const [isSponsorModalOpen, setSponsorModalOpen] = useState(false);
  const [isSessionModalOpen, setSessionModalOpen] = useState(false);
  const [selectedSpeaker, setSelectedSpeaker] = useState({
    name: "",
    company: "",
    position: "",
    image: "",
  });

  const [isLightboxOn, setLightBox] = useState(false);

  const [selectedSponsor, setSelectedSponsor] = useState({
    name: "",
    website: "",
    description: "",
    image: "",
    alt: "",
    type: "",
  });

  const closeSpeakerModal = () => {
    setSpeakerModalOpen(false);
    setSelectedSpeaker({
      name: "",
      company: "",
      position: "",
      image: "",
    });
  };

  const closeSponsorModal = () => {
    setSponsorModalOpen(false);
    setSelectedSponsor({
      name: "",
      website: "",
      description: "",
      image: "",
      alt: "",
      type: "",
    });
  };

  const closeSessionModal = () => {
    setSessionModalOpen(false);
    setSelectedSponsor({
      title: "",
      speakerName: "",
      speakerPosition: "",
      roomAndTime: "",
      description: "",
      image: "",
      alt: "",
    });
  };

  const renderHeadlineSpeakers = [
    {
      full_name: "Karen Mangia",
      company: "Salesforce",
      title: "Vice President, Customer and Market Insights",
      description: {
        html:
          "<p>As Vice President, Customer and Market Insights at Salesforce, Karen engages current and future customers globally to discover new ways of creating success and growth together. </p><p>Karen is a TEDx speaker who also delivers keynotes globally that inspire thousands each year. She has been recognized with the Centurion Award, Hall of Fame Honoree, and a Graduate of Distinction from Ball State University.<br><br>Her first book, Success With Less, was published in 2016.</p>",
      },
      image: "/images/Karen_Mangia.jpeg",
    },
    {
      full_name: "Kiran Manyala",
      company: "Salesforce",
      title: "Senior Manager,Developer Relations",
      description: {
        html:
          "<p>Developer Relations is being quarter each of a developer, speaker, blogger, and event organiser to help developers build on Salesforce platform.</p><p>Spent most of my time enabling the growing community of developers in the Salesforce eco system in India and APAC</p>",
      },
      image: "/images/Kiran_Manyala.png",
    },
  ];
  const renderSpeakers = [
    {
      full_name: "Deena Tearney",
      company: "Pacific Point",
      title: "Chief Executive Officer",
      description: {
        html:
          '<p>Founder and CEO of Pacific Point, with strong technical background of CRM software development and consulting with "Big Five" consulting firms.</p><p>She has a long-standing history of support for various youth programs in Hawaii focused on topics such as STEM, Women in Business and Leadership, and Women in Technology.</p><p>2016 Dreamforce speaker, panelist on Hawaii Salesforce Innovation Day.</p>',
      },
      image: "/images/Deena_Tearney.png",
    },
    {
      full_name: "Sebastian Wagner",
      company: "FlowRepublic",
      title: "CTA & Master Coach",
      description: {
        html:
          "<p>- CTA &amp; Master Coach<br>- Founder of FlowRepublic<br>- DF &amp; TDX speaker</p>",
      },
      image: "/images/Sebastian_Wagner.png",
    },
    {
      full_name: "Gildas Cante",
      company: "KMS",
      title: "Lead Einstein Analytics Consultant",
      description: {
        html:
          "<p>4x Salesforce Certified Consultant - 3 years both in EMEA and Europe working exclusively on Einstein Analytics for Salesforce Customers - Invited by Salesforce in 2018 to meet Einstein Analytics product team in San Francisco as a leading Trailblazer.</p>",
      },
      image: "/images/Gildas_Cante.png",
    },
    {
      full_name: "Priyanka G",
      company: "EF Education First",
      title: "Marketing Automation Manager",
      description: {
        html:
          "<p>Campaign management specialist and marketing automation manager with 5 years experience in journey builder, automation builder, email studio.</p>",
      },
      image: "/images/Priyanka_G.png",
    },
    {
      full_name: "Charles Thompson",
      company: "Salesforce",
      title: "Program Architect",
      description: {
        html:
          "<p>Salesforce Singapore PA for over 2 years, 15× certified &amp; 400+ TH badges. Specialist in Airlines × Service Cloud. Active pro bono consultant, currently advising 4 NPOs. Enterprise architect for 20 years, speaker, developer and consultant for 40 years.</p>",
      },
      image: "/images/Charles_Thompson.png",
    },
    {
      full_name: "Scott Allan",
      company: "Salesforce",
      title: "Sr. Manager, Roadmap Communications",
      description: {
        html:
          "<p>As part of the Salesforce Customer &amp; Market Insights team, Scott leads communications for the IdeaExchange Reimagined effort, connecting with customers so they know how they can help influence the Salesforce product roadmap. Previous to Salesforce, Scott held audit, technology and communication roles in the healthcare industry at McKesson, and IT advisory roles at EY</p>",
      },
      image: "/images/Scott_Allan.png",
    },
    {
      full_name: "Anuj Kumar Pankaj",
      company: "Saasfocus A Cognizant Company",
      title: "Technical Architect",
      description: {
        html:
          "<p>Technical Architect, 11 years of experience in Salesforce and various IT technologies. Salesforce Certified Domain Architect.Experienced in Salesforce consulting and implementations in Automotive, IT services, and marketing domains.</p>",
      },
      image: "/images/Anuj_Kumar_Pankaj.png",
    },
    {
      full_name: "Stephan Wissel",
      company: "HCL",
      title: "Director Innovation Lab",
      description: {
        html:
          "<p>Collaboration expert, ex Salesforce program architect, Blogger. Speaking on tech events for 20 years.</p>",
      },
      image: "/images/Stephan_Wissel.png",
    },
    {
      full_name: "Syed Insha Jawaid",
      company: "Saasfocus A Cognizant Company",
      title: "Associate - Projects",
      description: {
        html:
          "<p>Certified Salesforce Evangelist, 4+ Years as Aspiring Technical Architect holding designer certifications, Advocate of Quality and Best Practices, Active participant in Salesforce Communities.</p>",
      },
      image: "/images/Syed_Insha_Jawaid.png",
    },
    {
      full_name: "Marcelo Caponi",
      company: "Pernod Ricard EMEA",
      title: "Salesforce Solutions Architect",
      description: {
        html:
          "<p>I'm from Uruguay but I'm based in Paris from 2017. I work with the Salesforce platform since 2012 focused on applications and Integrations.<br>Currently, I work for Pernod Ricard EMEA and I'm in charge of the architecture of all Salesforce implementations.</p><p>About one year ago we developed from scratch a Mobile SFA solution delivered in 20 affiliates. In this solution, we have fully integrated Salesforce with 3 ERPs (JDE, SAP, NAV). </p>",
      },
      image: "/images/Marcelo_Caponi.jpeg",
    },
    {
      full_name: "Anna Loughnan",
      company: "Todd Digital",
      title: "Customer Success Manager / Internal Consultant",
      description: {
        html:
          "<p>Salesforce MVP, Lightning Champion, mentor, Dreamforce speaker 2019 (Trailblazer Theatre), 2017 (2 sessions), 2016, Sydney World Tour speaker 2019 (3 sessions), 2018 (2 sessions), Salesforce Basecamp Auckland speaker 2019 (2 sessions), 2018 (3 sessions, including featured Trailblazer during the keynote), 2017, speaker at Salesforce community events in Australia (Sydney and Melbourne) &amp; Holland, all in 2019.</p><p>Founder and leader of the very active Wellington Salesforce Trailblazer Community group, founded Wellington’s #TrailheadTuesday in April 2018, we continue to meet monthly. Trailhead 5x Ranger, 500+ badges.</p><p>Salesforce certifications achieved and maintained are Sales Cloud Consultant, Service Cloud Consultant, Administrator and Advanced Administrator, Platform App Builder, Sharing and Visibility Designer, Data Architecture and Management Designer. Now determinedly on the #JourneytoCTA</p>",
      },
      image: "/images/Anna_Loughnan.jpeg",
    },
    {
      full_name: "Prashant Singh",
      company: "TeamSpirit",
      title: "Software Engineer",
      description: {
        html:
          "<p>Prashant has more than 4 years of experience working in e-Commerce industry and Enterprise Resource Planning (ERP) solutions. He was invited to speak at SFDC events both in Singapore and Salesforce World Tour Tokyo' 20 within one year of his Salesforce journey.</p>",
      },
      image: "/images/Prashant_Singh.jpeg",
    },
    {
      full_name: "Yuhi Yoshida",
      company: "TeamSpirit",
      title: "Software Engineer",
      description: {
        html:
          "<p>Yuhi has more than 1.5 years working with Salesforce and Expense Management System for enterprises. He has shared about Einstein AI in both in Singapore and Tokyo Salesforce events.</p>",
      },
      image: "/images/Yuhi_Yoshida.png",
    },
    {
      full_name: "Aaron Lau",
      company: "Charket",
      title: "VP of Sales",
      description: {
        html:
          "<p>Aaron Lau, the Founder and VP of Sales of Charket, and a long time Salesforce advocate. Aaron came from a developer background and was the very first Salesforce developer in Mainland China from 15 years ago. He founded Meginfo, a Salesforce consulting company, in Beijing in 2008 and Charket, a Salesforce &amp; WeChat integration provider, in San Jose, California in 2013. The Charket app is the most popular and the most secure AppExchange app for integrating WeChat into Salesforce Sales Cloud, Service Cloud, Marketing Cloud and Pardot.</p>",
      },
      image: "/images/Aaron_Lau.jpeg",
    },
    {
      full_name: "Nitisha Jukareddy",
      company: "Salesforce",
      title: "Manager, Developer Relations",
      description: {
        html:
          "<p>I own the #Journey2Salesforce program and take care of Partner Relations for APAC Developer Relations.</p>",
      },
      image: "/images/Nitisha_Jukareddy.jpeg",
    },
    {
      full_name: "Vishwa Vikas Dagala",
      company: "Salesforce",
      title: "Associate Manager, Developer Relations",
      description: {
        html:
          "<p>Vishwa is part of the Developer Relations team for the past four years focusing on developer outreach, student programs, and community enablement. He spends most of his time inspiring developers to get started on the Salesforce Platform and evangelizes new platform features and products.</p>",
      },
      image: "/images/Vishwa_Vikas_Dagala.jpeg",
    },
    {
      full_name: "Hana Mandapat",
      company: "Salesforce",
      title: "Senior Director of AppExchange Program Marketing",
      description: {
        html:
          "<p>Hana is the Senior Director of AppExchange Program Marketing, where she leads campaigns and programs, focused on driving new partnerships and delivering new solutions on AppExchange.</p>",
      },
      image: "/images/Hana_Mandapat.jpeg",
    },
  ];

  const renderTitaniumSponsors = [
    {
      name: "Charket",
      website_url: "www.charket.com",
      description: {
        html:
          '<p>Charket is a Salesforce native app that helps businesses implement their WeChat strategies covering WeChat marketing, social selling/commerce, WeChat customer service, WeChat payment processing and many more with the least amount of effort. Charket connects WeChat business accounts and WeChat mini programs into Salesforce Sales Cloud, Service Cloud, Marketing Cloud and Pardot without having any servers in between. Charket offers the highest level of data security, the best performance, the most reliable connection and full scalability to support ever-changing business needs.</p><p>Find the Charket app on&nbsp;AppExchange&nbsp;and learn more about Charket at&nbsp;<a target="_blank" rel="noopener" href="http://www.charket.com/">www.charket.com</a><br><br>AppExchange URL: <a target="_blank" rel="noopener" href="https://appexchange.salesforce.com/listingDetail?listingId=a0N3A00000DqD6dUAF">https://appexchange.salesforce.com/listingDetail?listingId=a0N3A00000DqD6dUAF</a></p>',
      },
      logo: "/images/sponsor_titanium.webp",
      type: "TITANIUM",
    },
  ];
  const renderPlatinumSponsors = [
    {
      name: "Name",
      website_url: "",
      description: {
        html: "",
      },
      logo: "/images/sponsor_conga.webp",
      type: "PLATINUM",
    },
  ];
  const renderGoldSponsors = [
    {
      name: "Name",
      website_url: "",
      description: {
        html: "",
      },
      logo: "/images/sponor_validity.webp",
      type: "GOLD",
    },
    {
      name: "Name",
      website_url: "",
      description: {
        html: "",
      },
      logo: "/images/sponsor_dashcord.webp",
      type: "GOLD",
    },
    {
      name: "Name",
      website_url: "",
      description: {
        html: "",
      },
      logo: "/images/docuSign.webp",
      type: "GOLD",
    },
  ];
  const renderSilverSponsors = [
    {
      name: "Name",
      website_url: "",
      description: {
        html: "",
      },
      logo: "/images/sponor_xactly.webp",
      type: "SILVER",
    },
    {
      name: "Name",
      website_url: "",
      description: {
        html: "",
      },
      logo: "/images/sponor_sharin.webp",
      type: "SILVER",
    },
  ];
  const renderOtherSponsors = [
    {
      name: "Name",
      website_url: "",
      description: {
        html: "",
      },
      logo: "/images/sponor_ive.webp",
      type: "",
      label: "Cocktail Party Sponsor",
    },
    {
      name: "Name",
      website_url: "",
      description: {
        html: "",
      },
      logo: "/images/sponor_ploy.webp",
      type: "",
      label: "Coffee Cart Sponsor",
    },
    {
      name: "Name",
      website_url: "",
      description: {
        html: "",
      },
      logo: "/images/sponor_appirio.webp",
      type: "",
      label: "Lanyard Sponsor",
    },
    {
      name: "Name",
      website_url: "",
      description: {
        html: "",
      },
      logo: "/images/sponsor_charket.webp",
      type: "",
      label: "Headshots Sponsor",
    },
    {
      name: "Name",
      website_url: "",
      description: {
        html: "",
      },
      logo: "/images/sponsor_secure.webp",
      type: "",
      label: "Headshots Sponsor",
    },
  ];
  const schedule_list = [
    {
      time: "8.30 - 9.30am",
      label: "Registration",
    },
    {
      time: "9.30 - 10.30am",
      label: "Keynote #1",
    },
    {
      time: "10.30 - 4.00pm",
      label: "Breakout Sessions",
    },
    {
      time: "10.30 - 6.30pm",
      label: "Expo",
    },
    {
      time: "4.00 - 4.30pm",
      label: "Demo Jam",
    },
    {
      time: "4.30 - 5.30pm",
      label: "Keynote #2",
    },
    {
      time: "5.30 - 6.30pm",
      label: "Networking & Drinks",
    },
  ];
  const gallery_images = [
    { image: "/images/event_1.png" },
    { image: "/images/event_2.png" },
    { image: "/images/event_3.png" },
    { image: "/images/event_4.png" },
    { image: "/images/event_5.png" },
    { image: "/images/event_6.png" },
    { image: "/images/event_7.png" },
    { image: "/images/event_8.png" },
    { image: "/images/event_9.png" },
    { image: "/images/event_10.png" },
    { image: "/images/event_11.png" },
    { image: "/images/event_12.png" },
    { image: "/images/event_13.png" },
    { image: "/images/event_14.png" },
    { image: "/images/event_15.png" },
    { image: "/images/event_16.png" },
    { image: "/images/event_17.png" },
    { image: "/images/event_18.png" },
    { image: "/images/event_19.png" },
    { image: "/images/event_20.png" },
    { image: "/images/event_21.png" },
    { image: "/images/event_22.png" },
    { image: "/images/event_23.png" },
    { image: "/images/event_24.png" },
    { image: "/images/event_25.png" },
    { image: "/images/event_26.png" },
    { image: "/images/event_27.png" },
    { image: "/images/event_28.png" },
    { image: "/images/event_29.png" },
    { image: "/images/event_30.png" },
    { image: "/images/event_31.png" },
    { image: "/images/event_32.png" },
    { image: "/images/event_33.png" },
    { image: "/images/event_34.png" },
    { image: "/images/event_35.png" },
    { image: "/images/event_36.png" },
    { image: "/images/event_37.png" },
    { image: "/images/event_38.png" },
    { image: "/images/event_39.png" },
    { image: "/images/event_40.png" },
    { image: "/images/event_41.png" },
    { image: "/images/event_42.png" },
    { image: "/images/event_43.png" },
    { image: "/images/event_44.png" },
    { image: "/images/event_45.png" },
    { image: "/images/event_46.png" },
    { image: "/images/event_47.png" },
    { image: "/images/event_48.png" },
    { image: "/images/event_49.png" },
    { image: "/images/event_50.png" },
    { image: "/images/event_51.png" },
    { image: "/images/event_52.png" },
    { image: "/images/event_53.png" },
    { image: "/images/event_54.png" },
    { image: "/images/event_55.png" },
    { image: "/images/event_56.png" },
    { image: "/images/event_57.png" },
    { image: "/images/event_58.png" },
    { image: "/images/event_59.png" },
    { image: "/images/event_60.png" },
    { image: "/images/event_61.png" },
    { image: "/images/event_62.png" },
    { image: "/images/event_63.png" },
    { image: "/images/event_64.png" },
    { image: "/images/event_65.png" },
    { image: "/images/event_66.png" },
    { image: "/images/event_67.png" },
    { image: "/images/event_68.png" },
    { image: "/images/event_69.png" },
    { image: "/images/event_70.png" },
    { image: "/images/event_71.png" },
    { image: "/images/event_72.png" },
    { image: "/images/event_73.png" },
    { image: "/images/event_74.png" },
    { image: "/images/event_75.png" },
    { image: "/images/event_76.png" },
    { image: "/images/event_77.png" },
    { image: "/images/event_78.png" },
    { image: "/images/event_79.png" },
    { image: "/images/event_80.png" },
    { image: "/images/event_81.png" },
    { image: "/images/event_82.png" },
    { image: "/images/event_83.png" },
    { image: "/images/event_84.png" },
    { image: "/images/event_85.png" },
    { image: "/images/event_86.png" },
    { image: "/images/event_87.png" },
    { image: "/images/event_88.png" },
    { image: "/images/event_89.png" },
    { image: "/images/event_90.png" },
    { image: "/images/event_91.png" },
    { image: "/images/event_92.png" },
    { image: "/images/event_93.png" },
    { image: "/images/event_94.png" },
    { image: "/images/event_95.png" },
    { image: "/images/event_96.png" },
    { image: "/images/event_97.png" },
    { image: "/images/event_98.png" },
    { image: "/images/event_99.png" },
    { image: "/images/event_100.png" },
  ];

  return (
    <Layout>
      <SEO
        title={"Singapore Dreamin"}
        description={
          "Singapore Dreamin’ is a nonprofit event and so we are seeking sponsorship from the Salesforce community."
        }
        image={logo}
      />
      <Wrapper>
        {/* ======== START BANNER */}
        <section className="banner" id="top">
          <h1 className="banner__heading">Singapore Dreamin</h1>
          <h2 className="banner__subheading">15 January 2020</h2>
        </section>
        {/* ======== END BANNER */}
        {/* START INTRO */}
        <section className="intro" id="about">
          <div className="intro__text">
            <h1 className="intro-heading">CALLING ALL TRAILBLAZERS!</h1>
            <div className="intro-description">
              <h2>Singapore Dreamin 2020 Wrap Up!</h2>
              <p>
                We have concluded Singapore Dreamin 2020! It was a huge success
                - a day filled with networking opportunities with fellow
                Salesforce professionals, skilling up through insightful sharing
                by speakers from around the world, and collecting swags from our
                awesome sponsors. This marks the start of Salesforce community
                conferences in the new decade, making this inaugural event
                memorable. Check out the Speaker presentations from Singapore
                Dreamin 2020 here http://bit.ly/sgdreamin-slides{" "}
              </p>
              <br />
              <p>
                We’d like to thank our sponsors, our speakers, our attendees and
                especially you, for making this event possible. Thank you. We
                look forward to bringing you an even bigger and more exciting
                event in 2021! Do contact us if you're interested in the 2021
                event.{" "}
              </p>
            </div>
          </div>
          <div className="intro__brand">
            <div className="logo">
              <img src={colouredLogo} alt="Singapore Dreamin logo" />
            </div>
          </div>
        </section>
        {/* END INTRO */}

        <section className="organiser-wrapper">
          <div className="organiser">
            <h2 className="organiser__heading">
              ORGANISERS <span>/ meet the team</span>
            </h2>
            <img src="/images/team.png" alt="Group photo" />
          </div>
        </section>

        {/* START SPEAKER */}

        {renderHeadlineSpeakers.length + renderSpeakers.length > 0 && (
          <section className="speaker-wrapper">
            <div className="speaker">
              {renderHeadlineSpeakers.length > 0 && (
                <div className="speaker__top">
                  <h2 className="top-speaker-heading">HEADLINE SPEAKER</h2>
                  <p className="top-speaker-subheading">
                    Meet the headline speaker
                  </p>
                  <ul className="top-list">
                    {renderHeadlineSpeakers.map((speaker, i) => (
                      <li
                        className="top-list__item"
                        onClick={() => {
                          setSelectedSpeaker({
                            name: speaker.full_name,
                            company: speaker.company,
                            position: speaker.title,
                            description: speaker.description.html,
                            image: speaker.image,
                          });

                          setSpeakerModalOpen(true);
                        }}
                        key={i}
                      >
                        <div className="speaker-img">
                          {speaker.image && (
                            <img src={speaker.image} alt={speaker.full_name} />
                          )}
                        </div>
                        <h3 className="speaker-name">{speaker.full_name}</h3>
                        <p className="speaker-company">
                          {speaker.company} - {speaker.title}
                        </p>
                      </li>
                    ))}
                  </ul>
                </div>
              )}
              {renderSpeakers.length > 0 && (
                <div className="speaker__bottom">
                  <h2 className="bottom-speaker-heading">SPEAKERS</h2>
                  <p className="bottom-speaker-subheading">Meet the speakers</p>
                  <ul className="bottom-list">
                    {renderSpeakers.map((speaker, i) => (
                      <li
                        className="bottom-list__item"
                        onClick={() => {
                          setSelectedSpeaker({
                            name: speaker.full_name,
                            company: speaker.company,
                            position: speaker.title,
                            description: speaker.description.html,
                            image: speaker.image,
                          });

                          setSpeakerModalOpen(true);
                        }}
                        key={i}
                      >
                        <div className="speaker-img">
                          {speaker.image && (
                            <img src={speaker.image} alt={speaker.full_name} />
                          )}
                        </div>
                        <h3 className="speaker-name">{speaker.full_name}</h3>
                        <p className="speaker-company">
                          {speaker.company} - {speaker.title}
                        </p>
                      </li>
                    ))}
                  </ul>
                </div>
              )}
            </div>
          </section>
        )}

        <MailchimpForm
          registration_subheading="closed"
          registration_description="Please enter your email address below to be notified when registration is open."
        />

        <section className="agenda-wrapper" id="agenda">
          <div className="agenda">
            <h2 className="agenda__heading">AGENDA</h2>
            <p className="agenda__description">
              Get ready for an information-packed day of learning and
              networking. Hear insights from Salesforce executives about driving
              innovation and success. Find out how other Trailblazers, partners,
              and product experts use Salesforce to connect with their customers
              in a whole new way.
            </p>
            <ul className="agenda__schedule-list">
              {schedule_list.map((item, i) => (
                <li className="schedule-item" key={i}>
                  <span className="schedule-item__time">{item.time}</span>
                  <span className="schedule-item__label">{item.label}</span>
                </li>
              ))}
            </ul>
          </div>
        </section>
        {/* START SPONSOR */}
        <section className="sponsor-wrapper">
          <div className="sponsor">
            <h2 className="sponsor-heading">SPONSORS</h2>
            {renderTitaniumSponsors.length > 0 && (
              <div className="sponsor__3-col">
                <h3 className="sponsor-subheading">TITANIUM</h3>
                <ul className="list">
                  {renderTitaniumSponsors.map((sponsor, i) => (
                    <li
                      className="list__item"
                      style={{
                        cursor: sponsor.name !== `Name` ? `pointer` : `auto`,
                      }}
                      onClick={
                        sponsor.name !== `Name`
                          ? () => {
                              setSelectedSponsor({
                                name: sponsor.name,
                                website: sponsor.website_url,
                                description: sponsor.description.html,
                                image: sponsor.logo,
                                type: "TITANIUM",
                              });

                              setSponsorModalOpen(true);
                            }
                          : null
                      }
                      key={i}
                    >
                      {sponsor.logo && (
                        <span>
                          <img src={sponsor.logo} alt={sponsor.name} />
                        </span>
                      )}
                    </li>
                  ))}
                </ul>
              </div>
            )}
            {renderPlatinumSponsors.length > 0 && (
              <div className="sponsor__3-col">
                <h3 className="sponsor-subheading">PLATINUM</h3>
                <ul className="list">
                  {renderPlatinumSponsors.map((sponsor, i) => (
                    <li
                      className="list__item"
                      style={{
                        cursor: sponsor.name !== `Name` ? `pointer` : `auto`,
                      }}
                      onClick={
                        sponsor.name !== `Name`
                          ? () => {
                              setSelectedSponsor({
                                name: sponsor.name,
                                website: sponsor.website_url,
                                description: sponsor.description.html,
                                image: sponsor.logo,
                                type: "PLATINUM",
                              });

                              setSponsorModalOpen(true);
                            }
                          : null
                      }
                      key={i}
                    >
                      {sponsor.logo && (
                        <span>
                          <img src={sponsor.logo} alt={sponsor.name} />
                        </span>
                      )}
                    </li>
                  ))}
                </ul>
              </div>
            )}
            {renderGoldSponsors.length > 0 && (
              <div className="sponsor__4-col">
                <h3 className="sponsor-subheading">GOLD</h3>
                <ul className="list">
                  {renderGoldSponsors.map((sponsor, i) => (
                    <li
                      className="list__item"
                      style={{
                        cursor: sponsor.name !== `Name` ? `pointer` : `auto`,
                      }}
                      key={i}
                    >
                      {sponsor.logo && (
                        <span>
                          <img src={sponsor.logo} alt={sponsor.name} />
                        </span>
                      )}
                    </li>
                  ))}
                </ul>
              </div>
            )}
            {renderSilverSponsors.length > 0 && (
              <div className="sponsor__5-col">
                <h3 className="sponsor-subheading">SILVER</h3>
                <ul className="list">
                  {renderSilverSponsors.map((sponsor, i) => (
                    <li
                      className="list__item"
                      style={{
                        cursor: sponsor.name !== `Name` ? `pointer` : `auto`,
                      }}
                      key={i}
                    >
                      {sponsor.logo && (
                        <span>
                          <img src={sponsor.logo} alt={sponsor.name} />
                        </span>
                      )}
                    </li>
                  ))}
                </ul>
              </div>
            )}
            {renderOtherSponsors.length > 0 && (
              <div className="sponsor__5-col">
                <h3 className="sponsor-subheading">ADDITIONAL</h3>
                <ul className="list">
                  {renderOtherSponsors.map((sponsor, i) => (
                    <li
                      className="list__item"
                      style={{
                        cursor: sponsor.name !== `Name` ? `pointer` : `auto`,
                      }}
                      key={i}
                    >
                      {sponsor.logo && (
                        <span>
                          <img src={sponsor.logo} alt={sponsor.name} />
                          <span className="label">{sponsor.label}</span>
                        </span>
                      )}
                    </li>
                  ))}
                </ul>
              </div>
            )}
          </div>
        </section>

        {/* END SPONSOR */}

        {/* START INTEREST */}
        <section className="interest-wrapper" id="sponsoring">
          <div className="interest">
            <div className="interest__text">
              <h2 className="interest-heading">INTERESTED IN SPONSORING?</h2>
              <div className="interest-description">
                <p>
                  Singapore Dreamin is a nonprofit event and so we are seeking
                  sponsorship from the Salesforce community. It’s a great
                  opportunity to get your brand in front of engaged Salesforce
                  customers and there are many ways to get involved.
                </p>
                <p>
                  To finalise your sponsorship or request further information,
                  contact us below:
                </p>
              </div>
              <a
                class="contact-link"
                href="mailto:singaporedreamin@googlegroups.com"
              >
                CONTACT US
              </a>
            </div>
            <div className="interest__featured-img"></div>
          </div>
        </section>
        {isSpeakerModalOpen && (
          <SpeakerModal
            selectedSpeaker={selectedSpeaker}
            closeSpeakerModal={closeSpeakerModal}
          />
        )}

        {isSponsorModalOpen && (
          <SponsorModal
            selectedSponsor={selectedSponsor}
            closeSponsorModal={closeSponsorModal}
          />
        )}

        {isSessionModalOpen && (
          <SessionModal
            selectedSession={selectedSession}
            closeSessionModal={closeSessionModal}
          />
        )}

        <Gallery
          isLightboxOn={isLightboxOn}
          setLightBox={setLightBox}
          gallery_images={gallery_images}
        />
      </Wrapper>
    </Layout>
  );
};

export default IndexPage;
