import React, { useState } from "react";
import styled from "styled-components";
import addToMailchimp from "gatsby-plugin-mailchimp";
import {
  raleway,
  mainWhite,
  dirtyWhite,
  mainBlack,
  mainRed,
  lightRed,
  lessBlack,
  innerWidth,
  transHover,
  screen,
  emailRegex,
} from "../common/variables";

const Wrapper = styled.div`
  max-width: ${innerWidth};
  padding: 0 25px;
  @media ${screen.small} {
    padding: 0 35px;
    margin: 0 auto 0 auto;
  }
  @media ${screen.large} {
    margin: 0 auto 0 auto;
    padding: 0;
  }

  .registration {
    max-width: ${innerWidth};
    margin: 0 auto;
    box-shadow: 0 4px 12px -2px rgba(0, 0, 0, 0.2);
    border-radius: 22px;
    background: ${dirtyWhite};
    max-width: ${innerWidth};
    padding-top: 30px;
    padding-bottom: 35px;
    padding-left: 35px;
    padding-right: 35px;
    @media ${screen.small} {
      padding-top: 55px;
      padding-bottom: 55px;
      padding-left: 55px;
      padding-right: 55px;
    }
    @media ${screen.medium} {
      padding-top: 70px;
      padding-bottom: 70px;
      padding-left: 70px;
    }

    &__heading {
      color: ${lessBlack};
      font-size: 1.2rem;
      font-weight: 900;
      font-family: ${raleway};
      margin-bottom: 20px;
      padding-right: 20px;
      @media ${screen.small} {
        font-size: 1.52rem;
      }

      span {
        font-weight: 300;
      }
    }

    &__description {
      max-width: 850px;
      font-size: 1.18rem;
      font-weight: 300;
      font-family: ${raleway};
      margin-bottom: 16px;
      @media ${screen.small} {
        font-size: 1.26rem;
      }

      p {
        font-size: 1.18rem;
        font-weight: 300;
        font-family: ${raleway};
        margin-bottom: 16px;
        @media ${screen.small} {
          font-size: 1.36rem;
        }
        &:last-child {
          margin-bottom: 0;
        }
      }

      strong {
        font-weight: 700;
      }

      a {
        color: ${mainBlack};
        text-decoration: underline;
        transition: ${transHover};
        &:hover {
          color: ${mainRed};
        }
      }

      ul,
      ol {
        padding-left: 14px;
        margin-bottom: 20px;
        @media ${screen.small} {
          padding-left: 20px;
        }
        &:last-child {
          margin-bottom: 0;
        }

        li {
          font-size: 1.18rem;
          font-weight: 300;
          font-family: ${raleway};
          margin-bottom: 16px;
          @media ${screen.small} {
            font-size: 1.36rem;
          }
          &:last-child {
            margin-bottom: 0;
          }
        }
      }

      ul {
        li {
          list-style-type: disc;
          list-style-position: inside;
        }
      }

      ol {
        li {
          list-style-type: decimal;
          list-style-position: inside;
        }
      }

      h1,
      h2,
      h3,
      h4,
      h5,
      h6 {
        font-size: 1.3rem;
        font-weight: 500;
        margin-bottom: 16px;
        font-family: ${raleway};
        @media ${screen.small} {
          font-size: 1.49rem;
        }
        &:last-child {
          margin-bottom: 0;
        }
      }
    }

    &__link {
      border: 0;
      background: ${mainRed};
      border-radius: 8px;
      color: ${mainWhite};
      cursor: pointer;
      display: table;
      font-family: ${raleway};
      font-weight: 600;
      font-size: 0.95rem;
      padding: 11px 42px;
      height: 100%;
      transition: ${transHover};
      outline-color: ${lightRed};
      text-transform: uppercase;
      margin-top: 16px;
      display: table;
      @media ${screen.medium} {
        font-size: 1rem;
        padding: 13px 57px;
      }
      @media ${screen.withCursor} {
        &:hover {
          background: ${lightRed};
        }
      }
    }

    &__bottom-text {
      font-size: 1rem;
      font-weight: 300;
      font-family: ${raleway};
      margin-top: 32px;
      @media ${screen.small} {
        font-size: 1.15rem;
      }
      @media ${screen.large} {
        white-space: pre-wrap;
      }
    }

    &__form {
      display: flex;
      margin-top: 20px;
      flex-direction: column;
      @media ${screen.medium} {
        flex-direction: row;
      }
      .col1 {
        input {
          font-family: ${raleway};
          font-size: 1.1rem;
          font-weight: 300;
          font-family: ${raleway};
          padding: 9px;
          outline-color: ${lightRed};
          width: 100%;
          @media ${screen.small} {
            font-size: 1.15rem;
            padding: 10px;
            width: 410px;
          }
        }
      }

      .col2 {
        button {
          border: 0;
          background: ${mainRed};
          border-radius: 8px;
          color: ${mainWhite};
          cursor: pointer;
          display: table;
          font-family: ${raleway};
          font-weight: 600;
          font-size: 0.95rem;
          padding: 11px 42px;
          height: 100%;
          transition: ${transHover};
          outline-color: ${lightRed};
          margin-top: 17px;
          @media ${screen.medium} {
            font-size: 1rem;
            padding: 13px 57px;
            margin-left: 17px;
            margin-top: 0;
          }
          @media ${screen.withCursor} {
            &:hover {
              background: ${lightRed};
            }
          }
        }
      }
    }

    &__feedback {
      font-family: ${raleway};
      color: ${mainBlack};
      font-size: 1rem;
      margin-top: 13px;
      @media ${screen.small} {
        font-size: 1.2rem;
        margin-top: 20px;
      }
    }
  }
`;

const MailchimpForm = ({ registration_subheading }) => {
  const [email, setEmail] = useState("");
  const [feedback, setFeedback] = useState("");

  const handleSubmit = e => {
    e.preventDefault();

    if (!emailRegex.test(email)) {
      // if email is invalid
      setFeedback("Please enter a valid email address.");
      setEmail("");
    } else {
      setFeedback("Sending....");
      addToMailchimp(email).then(data => {
        const checkEmail = data.msg.toLowerCase().split(" ");
        if (
          checkEmail.includes(email.toLowerCase()) ||
          checkEmail.includes(`"${email.toLowerCase()}"`)
        ) {
          // if email already in the list
          setFeedback("Looks like you're already subscribed.");
          setEmail("");
        } else {
          // if email successfully added
          setFeedback("Thanks for signing up!");
          setEmail("");
        }
      });
    }
  };

  return (
    <Wrapper id="registration">
      <div className="registration">
        <h2 className="registration__heading">
          REGISTRATION <span>/ {registration_subheading}</span>
        </h2>
        <p className="registration__description">
          Please enter your email address below to be notified when registration
          is open.
        </p>

        <form className="registration__form" onSubmit={handleSubmit}>
          <p></p>
          <div className="col1">
            <input
              onClick={() => setFeedback("")}
              type="text"
              onChange={e => setEmail(e.target.value)}
              value={email}
            />{" "}
          </div>
          <div className="col2">
            <button>SUBMIT</button>
          </div>
        </form>

        {feedback && <p className="registration__feedback">{feedback}</p>}
      </div>
    </Wrapper>
  );
};

export default MailchimpForm;
