import React, { useState, useEffect } from "react";
import styled from "styled-components";
import {
  innerWidth,
  mainWhite,
  mainRed,
  lightRed,
  transHover,
  raleway,
  screen,
  fadeIn,
} from "../common/variables";
import Carousel from "@brainhubeu/react-carousel";
import "@brainhubeu/react-carousel/lib/style.css";
import downloadIcon from "../../images/download-icon.svg";
import download from "downloadjs";

const Wrapper = styled.section`
  font-family: ${raleway};
  max-width: ${innerWidth};
  margin: 0 auto;
  padding: 55px 25px;
  @media ${screen.small} {
    padding: 55px 35px;
  }
  @media ${screen.large} {
    padding: 70px 0;
  }

  .gallery-heading {
    font-size: 1.2rem;
    font-weight: 900;
    margin-bottom: 3px;
    @media ${screen.small} {
      font-size: 1.52rem;
      margin-bottom: 5px;
    }
  }

  .gallery-description {
    font-size: 1.1rem;
    font-weight: 300;
    margin-bottom: 16px;
    @media ${screen.small} {
      font-size: 1.15rem;
    }
  }

  .gallery-list {
    display: flex;
    flex-wrap: wrap;
    margin-left: -10px;
    margin-right: -10px;
    margin-top: 30px;

    &__each {
      width: calc(50% - 20px);
      margin: 10px;
      max-height: 100px;
      overflow: hidden;
      cursor: pointer;
      border-radius: 14px;
      @media ${screen.small} {
        max-height: 138px;
        width: calc(25% - 20px);
      }
      @media ${screen.medium} {
        width: calc(20% - 20px);
      }
    }
  }

  .gallery-lightbox {
    animation: ${fadeIn} 0.3s ease-out;
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    top: 0;
    background: rgba(0, 0, 0, 0.9);
    z-index: 10;

    &__inner {
      width: 100%;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      max-width: 970px;

      .each-slide {
        width: 100%;
        max-width: 970px;
        /* max-width: 700px; */
        /* max-height: 970px; */
        position: relative;

        .close-icon {
          color: ${mainRed};
          font-size: 1.5rem;
          font-weight: 900;
          font-family: ${raleway};
          position: absolute;
          top: 0;
          right: 10px;
          cursor: pointer;
          transition: ${transHover};
          @media ${screen.small} {
            font-size: 1.8rem;
            top: 10px;
            right: 0;
          }
        }
      }

      .download-icon {
        cursor: pointer;
        display: table;

        img {
          width: 22px;
          height: 22px;
          display: block;
          margin-bottom: 10px;
          margin-left: 10px;
          transition: ${transHover};
          @media ${screen.small} {
            margin-bottom: 20px;
            margin-left: 0;
            width: 28px;
            height: 28px;
          }
          @media ${screen.withCursor} {
            &:hover {
              opacity: 0.6;
            }
          }
        }
      }

      .nav {
        position: absolute;
        font-size: 2.5rem;
        top: calc(50% + 10px);
        transform: translateY(-50%);
        cursor: pointer;
        @media ${screen.small} {
          font-size: 3rem;
          top: 50%;
        }

        &--prev {
          color: ${mainWhite};
          left: 10px;
          @media ${screen.small} {
            left: -10px;
          }
        }

        &--next {
          color: ${mainWhite};
          right: 10px;
          @media ${screen.small} {
            right: -10px;
          }
        }
      }
    }
  }

  .gallery-btn-more {
    border: 0;
    background: ${mainRed};
    border-radius: 8px;
    color: ${mainWhite};
    cursor: pointer;
    display: table;
    font-family: ${raleway};
    font-weight: 600;
    font-size: 0.95rem;
    padding: 11px 42px;
    height: 100%;
    transition: ${transHover};
    outline-color: ${lightRed};
    margin-top: 30px;
    @media ${screen.medium} {
      font-size: 1rem;
      padding: 13px 57px;
      margin-top: 55px;
    }
    @media ${screen.withCursor} {
      &:hover {
        background: ${lightRed};
      }
    }
  }
`;

const Gallery = ({ gallery_images, isLightboxOn, setLightBox }) => {
  const [selectedSlide, setSlide] = useState(0);

  const [galleryLength, setLength] = useState(15);

  // close lightbox if user has clicked outside of lightbox
  useEffect(() => {
    const userClick = e => {
      if (e.target.tagName === "DIV") {
        setLightBox(false);
      }
    };
    if (isLightboxOn) {
      window.addEventListener("click", userClick);

      return () => {
        window.removeEventListener("click", userClick);
      };
    }
  }, [isLightboxOn]);

  // all gallery to render
  const slides = gallery_images.map((image, i) => (
    <div
      className="each-slide"
      key={i}
      index={i}
      style={{
        maxWidth: 610,
      }}
    >
      <span
        className="close-icon"
        onClick={() => {
          setLightBox(false);
        }}
      >
        X
      </span>
      <span className="download-icon" onClick={() => downloadImg(image.image)}>
        <img src={downloadIcon} alt="download" />
      </span>
      <im src={image.image} alt={image.image} />
    </div>
  ));

  // current slide updater
  const slideChange = val => {
    setSlide(val);
  };

  // download img from url
  const downloadImg = url => {
    download(url);
  };

  return (
    <Wrapper>
      <h2 className="gallery-heading">2020 EVENT GALLERY</h2>
      <p className="gallery-description">
        Check out the photos from our 2020 event.
      </p>
      <ul className="gallery-list">
        {gallery_images.slice(0, galleryLength).map((image, i) => (
          <li
            onClick={() => {
              setSlide(i);
              setLightBox(true);
            }}
            className="gallery-list__each"
            key={i}
          >
            <img style={{ width: "100%" }} src={image.image} alt={image.alt} />
          </li>
        ))}
      </ul>

      {/* hide load more button if galleryLength equal or exceeded total gallery_images.length */}
      {gallery_images.length <= galleryLength ? null : (
        <button
          className="gallery-btn-more"
          onClick={() => setLength(galleryLength + 15)}
        >
          LOAD MORE
        </button>
      )}

      {isLightboxOn && (
        <div className="gallery-lightbox">
          <div className="gallery-lightbox__inner">
            <Carousel
              value={selectedSlide}
              slides={slides}
              onChange={slideChange}
              animationSpeed={0}
              draggable={false}
            />
            {selectedSlide > 0 && (
              <span
                className="nav nav--prev"
                onClick={() => {
                  setSlide(selectedSlide - 1);
                }}
              >
                {" "}
                {" < "}{" "}
              </span>
            )}

            {selectedSlide !== gallery_images.length - 1 && (
              <span
                className="nav nav--next"
                onClick={() => {
                  setSlide(selectedSlide + 1);
                }}
              >
                {" "}
                {" > "}{" "}
              </span>
            )}
          </div>
        </div>
      )}
    </Wrapper>
  );
};

export default Gallery;
